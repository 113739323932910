import {MerchantAutomationLambda} from '@amzn/f3-intraday-forecast-merchant-automation-api';
import {AsyncStatus, useExecute} from './use-async';
import {DependencyList, useCallback, useState} from 'react';
import {wrapOnErrorListener} from '../common';

/* eslint-disable react-hooks/exhaustive-deps */
export function useListMerchants(
    clientConfiguration: MerchantAutomationLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new MerchantAutomationLambda(clientConfiguration)
) {
    return useExecute<
        MerchantAutomationLambda.Types.ListMerchantResponse,
        (r: MerchantAutomationLambda.Types.ListMerchantRequest) => Promise<MerchantAutomationLambda.Types.ListMerchantResponse>
    >(
        useCallback(
            (request: MerchantAutomationLambda.Types.ListMerchantRequest) =>
                wrapOnErrorListener(client.listMerchant(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useGetMerchant(
    clientConfiguration: MerchantAutomationLambda.Types.ClientConfiguration,
    request: MerchantAutomationLambda.Types.GetMerchantRequest,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new MerchantAutomationLambda(clientConfiguration)
) {
    return useExecute(useCallback(() => wrapOnErrorListener(client.getMerchant(request).promise(), onErrorListener), deps));
}

export function useOnboardMerchant(
    clientConfiguration: MerchantAutomationLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new MerchantAutomationLambda(clientConfiguration)
) {
    return useExecute<unknown, (r: MerchantAutomationLambda.Types.OnboardMerchantRequest) => Promise<unknown>>(
        useCallback(
            (request: MerchantAutomationLambda.Types.OnboardMerchantRequest) =>
                wrapOnErrorListener(client.onboardMerchant(request).promise(), onErrorListener),
            deps
        )
    );
}

export interface GetMerchantsFetchStatus {
    status: AsyncStatus;
    error?: string;
    merchants: MerchantAutomationLambda.Types.Merchant[];
}

export function useGetMultipleMerchants(
    clientConfiguration: MerchantAutomationLambda.Types.ClientConfiguration,
    deps: DependencyList,
    client = new MerchantAutomationLambda(clientConfiguration)
) {
    const [value, setValue] = useState<GetMerchantsFetchStatus>({
        status: 'idle',
        merchants: [],
    });

    const execute = useExecute<
        MerchantAutomationLambda.Types.Merchant[],
        (r: string[]) => Promise<MerchantAutomationLambda.Types.Merchant[]>
    >(
        useCallback(async (nodeIds: string[]) => {
            try {
                setValue({status: 'pending', merchants: []});
                const merchants: MerchantAutomationLambda.Types.Merchant[] = [];
                for (const nodeId of nodeIds) {
                    const response = await client
                        .getMerchant({
                            nodeId,
                        })
                        .promise();
                    if (response.merchant) {
                        merchants.push(response.merchant);
                    }
                }
                setValue({status: 'success', merchants: merchants} as GetMerchantsFetchStatus);
                return Promise.resolve(merchants);
            } catch (error) {
                setValue({status: 'error', merchants: []});
                return Promise.reject([]);
            }
        }, deps)
    );

    return {
        getMultipleMerchants: execute,
        getMultipleMerchantFetchStatus: value,
    };
}
