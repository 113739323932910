import React, {useState} from 'react';
import {Button, Container, Header, Select, SelectProps, SpaceBetween} from '@amzn/awsui-components-react';
import '../../assets/styles/business-selection-styles.scss';
import {useAuth} from '../hooks/use-auth';
import {useRouter} from '../hooks/use-router';

export default function BusinessSelection() {
    const router = useRouter();
    const auth = useAuth();
    const [businessId, setBusinessId] = useState(null as SelectProps.Option | null);
    const [country, setCountry] = useState(null as SelectProps.Option | null);
    const [countryOptions, setCountryOptions] = useState([] as SelectProps.Option[]);

    const businessIdOptions = Array.from(new Set((auth.authInformation?.businessContexts || []).map((v) => v.businessId))).map(
        (v) => ({label: getBusinessIdDisplayNames(v), value: v} as SelectProps.Option)
    );

    return (
        <div className="switch-business-main-content">
            <Container
                header={
                    <Header variant="h2" className="main-header">
                        Select Business for Excelsior
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="xxl">
                    <Select
                        data-testid="business-selection"
                        className={'business-dropdown'}
                        placeholder={'Select Business'}
                        selectedOption={businessId}
                        onChange={({detail}) => {
                            setBusinessId(detail.selectedOption);

                            const newCountryOptions = Array.from(
                                new Set(
                                    (auth.authInformation?.businessContexts || [])
                                        .filter((v) => !detail.selectedOption.value || v.businessId === detail.selectedOption.value)
                                        .map((v) => v.country)
                                )
                            ).map((v) => ({label: v, value: v} as SelectProps.Option));

                            setCountryOptions(newCountryOptions);
                            setCountry(null);
                        }}
                        options={businessIdOptions}
                        selectedAriaLabel="Selected"
                    />

                    <Select
                        data-testid="country-selection"
                        className={'country-dropdown'}
                        placeholder={'Select Country'}
                        selectedOption={country}
                        onChange={({detail}) => setCountry(detail.selectedOption)}
                        options={countryOptions}
                        selectedAriaLabel="Selected"
                        disabled={!businessId?.value}
                    />

                    <div className="switch-business-button">
                        <Button
                            data-testid="enter-button"
                            disabled={!businessId?.value || !country?.value}
                            onClick={async () => {
                                const selectedBusinessContext = (auth.authInformation?.businessContexts || [])
                                    .filter((v) => v.businessId === businessId?.value)
                                    .filter((v) => v.country === country?.value)[0];

                                await auth.authInformation?.switchTo(
                                    businessId?.value || '',
                                    country?.value || '',
                                    selectedBusinessContext.flow
                                );

                                // Let's app do the routing
                                router.push('/');
                            }}
                        >
                            Enter Portal
                        </Button>
                    </div>
                </SpaceBetween>
            </Container>
        </div>
    );
}

export function getBusinessIdDisplayNames(businessId: string): string {
    const businessIdToDisplayNameMap: {[key: string]: string} = {
        wfm: 'WFMOA',
        wfminstore: 'WFM INSTORE',
        uff: 'AFO',
    };

    if (businessIdToDisplayNameMap[businessId]) {
        return businessIdToDisplayNameMap[businessId].toUpperCase();
    }
    return businessId.toUpperCase();
}
