import React, {useRef, useState} from 'react';
import {Notification} from '../navigation/page-layout';
import {translateErrorToReactNode} from '../common';
import {RegionId, useAuth} from '../hooks/use-auth';
import {useGetMultipleMerchants, useListMerchants, useOnboardMerchant} from '../hooks/use-merchant-automation-api';
import {MerchantAutomationLambda} from '@amzn/f3-intraday-forecast-merchant-automation-api';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Icon,
    Input,
    Modal,
    Multiselect,
    Pagination,
    ProgressBar,
    SelectProps,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';

export function MerchantAutomation(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();

    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [selectedItems, setSelectedItems] = useState([] as MerchantAutomationLambda.Types.Merchant[]);
    const [visible, setVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [nextToken, setNextToken] = useState<string>();
    const [pageSize, setPageSize] = useState(50);
    const [nodeIdS, setNodeIdS] = useState<string[]>([]);
    const [nodeIdSearch, setNodeIdSearch] = useState('');
    const [paginationDisabled, setPaginationDisabled] = useState(false);
    const [localMerchants, setLocalMerchants] = useState(new Map<number, MerchantAutomationLambda.Types.Merchant[]>());
    const [selectedOptions, setSelectedOptions] = useState<SelectProps.Options>([]);

    const isLoading = useRef<boolean>(true);
    const pageCount = useRef(1);
    const storeCount = useRef(0);
    const initialized = useRef(false);

    const endpoints = auth.authInformation!.getEndpoints();
    /**
     * Use any region to get the "global" client configuration from the list of endpoints, we will use the default "NA" region for now
     */
    const clientConfiguration = endpoints[RegionId.NA].MerchantAutomation;

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const {
        execute: excuteListMerchant,
        status: listMerchantsStatus,
        value: listMerchantsResponse,
    } = useListMerchants(clientConfiguration, createErrorListener('ListMerchant failed'), [auth, nextToken]);

    isLoading.current = listMerchantsStatus === 'idle' || listMerchantsStatus === 'pending';
    if (!initialized.current) {
        excuteListMerchant({maxResults: pageSize});
        initialized.current = true;
    }

    const {getMultipleMerchantFetchStatus, getMultipleMerchants} = useGetMultipleMerchants(clientConfiguration, [auth]);
    const {execute: executeOnboardMerchant} = useOnboardMerchant(
        clientConfiguration,
        createErrorListener('OnboardMerchant failed'),
        [auth]
    );

    /**
     * Only load initial set of stores if localMerchants is empty
     */
    if (!isLoading.current && localMerchants.size === 0 && !paginationDisabled) {
        setLocalMerchants(localMerchants.set(currentPageIndex, listMerchantsResponse?.merchants ?? []));
        storeCount.current = storeCount.current + (localMerchants.get(currentPageIndex)?.length ?? 0);
        setNextToken(listMerchantsResponse?.nextToken);
    }

    const columnDefinitions = [
        {
            id: 'merchantId',
            header: 'Store ID',
            cell: (item: MerchantAutomationLambda.Types.Merchant) => item.nodeId,
            minWidth: 50,
        },
        {
            id: 'business',
            header: 'Business',
            cell: (item: MerchantAutomationLambda.Types.Merchant) => item.business,
            minWidth: 50,
        },
        {
            id: 'country',
            header: 'Country',
            cell: (item: MerchantAutomationLambda.Types.Merchant) => item.country,
            minWidth: 50,
        },
        {
            id: 'logisticTypes',
            header: 'Logistic Types',
            cell: (item: MerchantAutomationLambda.Types.Merchant) => item.logisticTypes?.join(', '),
            minWidth: 50,
        },
        {
            id: 'launchDate',
            header: 'Launch Date',
            cell: (item: MerchantAutomationLambda.Types.Merchant) => {
                return item.launchDate
                    ? `${item.launchDate} - ${item.isLive ? 'Store is live' : 'Store is deprecated'}`
                    : 'Not yet launched';
            },
            minWidth: 50,
        },
        {
            id: 'onboardingStatus',
            header: 'Onboarding Status',
            cell: (item: MerchantAutomationLambda.Types.Merchant) => (
                <ProgressBar
                    value={item.onboardingCompletionPercentage ?? 0}
                    description={item.onboardingStatusDescription}
                    label={
                        (item.onboardingCompletionPercentage ?? 0) === 0
                            ? 'Onboarding not started'
                            : (item.onboardingCompletionPercentage ?? 0) === 100
                            ? 'Onboarding completed'
                            : 'Onboarding in progress'
                    }
                />
            ),
            minWidth: 50,
        },
        {
            id: 'alerts',
            header: 'Alerts',
            cell: (item: MerchantAutomationLambda.Types.Merchant) => {
                if (item.merchantErrors?.length) {
                    return (
                        <Button
                            variant="link"
                            onClick={() => {
                                setAlertMessage(item.merchantErrors.join('/n'));
                                setVisible(true);
                            }}
                        >
                            <Icon name="status-warning" variant="error" />
                        </Button>
                    );
                }
                return (
                    <Button variant="link">
                        <Icon name="status-positive" variant="success" />
                    </Button>
                );
            },
            minWidth: 50,
        },
    ];

    return (
        <React.Fragment>
            <div className="main-header">
                <Header variant="h2">Intraday Forecasting Merchant Automation</Header>
            </div>
            <div className="main-content">
                <Container
                    header={
                        <Header
                            variant="h2"
                            description="Refine your search by selecting a search field and entering a search text below."
                        >
                            Search stores
                        </Header>
                    }
                >
                    <ColumnLayout columns={2}>
                        <div>
                            <FormField label="Store Id's">
                                <Input
                                    data-testid="search-text"
                                    value={nodeIdSearch}
                                    placeholder="Enter store Id's in comma-delimited form, e.g. AZLXNO6O5HUZG,A1WLPFK2G4U7Y5"
                                    onChange={({detail}) => {
                                        setNodeIdSearch(detail.value);
                                        setPaginationDisabled(!!detail.value);
                                    }}
                                />
                            </FormField>
                        </div>
                        <div>
                            <FormField label="Onboarding Status">
                                <Multiselect
                                    data-testid="onboarding-status-select"
                                    selectedOptions={selectedOptions}
                                    onChange={({detail}) => setSelectedOptions(detail.selectedOptions)}
                                    deselectAriaLabel={(e) => `Remove ${e.label}`}
                                    options={[
                                        {
                                            label: 'Training Inputs Enabled for Delivery',
                                            value: 'training_inputs_delivery_enabled',
                                        },
                                        {
                                            label: 'Training Inputs Enabled for Pickup',
                                            value: 'training_inputs_pickup_enabled',
                                        },
                                        {
                                            label: 'Predictions Enabled',
                                            value: 'predictions_enabled',
                                        },
                                        {
                                            label: 'Unconstrained Demand Enabled',
                                            value: 'ud_enabled',
                                        },
                                    ]}
                                    placeholder="Choose onboarding status"
                                    selectedAriaLabel="Selected"
                                />
                            </FormField>
                        </div>
                        <div>
                            <Button
                                data-testid="search-button"
                                onClick={async () => {
                                    pageCount.current = 1;
                                    if (nodeIdSearch) {
                                        const response = await getMultipleMerchants.execute(nodeIdSearch.split(','));
                                        storeCount.current = response?.length ?? 0;
                                        setLocalMerchants(localMerchants.set(1, response ?? []));
                                        setPaginationDisabled(true);
                                        setNodeIdS(nodeIdSearch.split(','));
                                    } else {
                                        setPaginationDisabled(false);
                                        const onboardingStatus = selectedOptions.map((option: SelectProps.Option) => option.value);
                                        const response = await excuteListMerchant({
                                            maxResults: pageSize,
                                            filterContains:
                                                onboardingStatus.length > 0 ? JSON.stringify({onboardingStatus}) : undefined,
                                        });
                                        storeCount.current = response?.merchants?.length ?? 0;
                                        setLocalMerchants(localMerchants.set(1, response?.merchants ?? []));
                                        setNextToken(response?.nextToken);
                                        setNodeIdS([]);
                                    }
                                    setCurrentPageIndex(1);
                                }}
                            >
                                Submit Search
                            </Button>
                        </div>
                        <div></div>
                    </ColumnLayout>
                </Container>
                <Table
                    data-testid="merchants"
                    selectionType="multi"
                    onSelectionChange={({detail}) => setSelectedItems(detail.selectedItems)}
                    selectedItems={selectedItems}
                    isItemDisabled={(item) =>
                        (item.onboardingCompletionPercentage ?? 0) === 100 ||
                        !!item.currentMerchantOnboardingStepFunctionExecutionId
                    }
                    columnDefinitions={columnDefinitions}
                    items={localMerchants.get(currentPageIndex) ?? []}
                    loading={isLoading.current || getMultipleMerchantFetchStatus?.status === 'pending'}
                    loadingText="Loading merchants"
                    wrapLines
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No merchants</b>
                        </Box>
                    }
                    header={
                        <Header
                            counter={`(${storeCount.current.toString()})`}
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        data-testid="onboarding-button"
                                        disabled={selectedItems.length === 0}
                                        variant="primary"
                                        onClick={async () => {
                                            const nodeIdString = selectedItems.map((item) => item.nodeId).join(',');
                                            props.pushNotification({
                                                content: `Onnboarding process for stores ${nodeIdString} started/resumed.`,
                                                type: 'info',
                                            });
                                            await Promise.all(
                                                selectedItems.map((item) => executeOnboardMerchant({nodeId: item.nodeId}))
                                            );
                                            props.pushNotification({
                                                content: `Start/resume onboarding for stores ${nodeIdString} completed.`,
                                                type: 'success',
                                            });
                                            setSelectedItems([]);
                                        }}
                                    >
                                        Start/Resume Onboarding
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            Stores found
                        </Header>
                    }
                    pagination={
                        <Pagination
                            data-testid="pagination"
                            ariaLabels={{
                                nextPageLabel: 'Next page',
                                previousPageLabel: 'Previous page',
                                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                            }}
                            disabled={paginationDisabled}
                            currentPageIndex={currentPageIndex}
                            onChange={({detail}) => {
                                setCurrentPageIndex(detail.currentPageIndex);
                            }}
                            onNextPageClick={async ({detail}) => {
                                if (!detail.requestedPageAvailable && listMerchantsResponse?.nextToken) {
                                    pageCount.current = pageCount.current + 1;
                                    const onboardingStatus = selectedOptions.map((option: SelectProps.Option) => option.value);
                                    const response = await excuteListMerchant({
                                        maxResults: pageSize,
                                        nextToken: nextToken,
                                        filterContains:
                                            onboardingStatus.length > 0 ? JSON.stringify({onboardingStatus}) : undefined,
                                    });
                                    storeCount.current = storeCount.current + (response?.merchants?.length ?? 0);
                                    setLocalMerchants(localMerchants.set(detail.requestedPageIndex, response?.merchants ?? []));
                                    setNextToken(response?.nextToken);
                                }
                            }}
                            pagesCount={pageCount.current}
                            openEnd
                        />
                    }
                />
                <Modal
                    data-testid="merchant-error-modal"
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    closeAriaLabel="Close modal"
                    size="medium"
                    header="Merchant Errors"
                >
                    {alertMessage}
                </Modal>
            </div>
        </React.Fragment>
    );
}
